import * as React from 'react';
import { Link } from 'gatsby';

function NotFoundPage() {
  return (
    <div className="page-404">
      <h2>The page you are trying to access, does not exist.</h2>
      <Link to="/">Back to Home</Link>
    </div>
  );
}

export default NotFoundPage;
